body {
    font-size:12px !important;
}
.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: none;
}

/*Typography*/
h1, .h1 {
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 300;
    line-height: 1.15;
}

@media (max-width: 768px) {
    h1, .h1 {
        font-size: 30px;
    }
}

h2, .h2 {
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 300;
    line-height: 1.2;
}

@media (max-width: 768px) {
    h2, .h2 {
        font-size: 26px;
    }
}
h3, .h3 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.25;
}
h4, .h4 {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.3;
}

h5, .h5 {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.35;
}

h6, .h6 {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
}

a:hover {
    color: inherit;
    text-decoration: none;
}

a {
    color: inherit;
    text-decoration: none;
}

.navbar {
    padding: 0;
    a.nav-link {
        text-transform: uppercase;
        color: $headings-color;
        font-weight: 500;

        &:hover {
            color: $primary;
        }
    }
}
.toolbar-dropdown,
.site-header .site-menu > ul > li .sub-menu {
    width: auto;
    min-width: 200px;
}

.logo {
    @include media-breakpoint-up(md) {
        width: 160px;
    }

    @include media-breakpoint-down(sm) {
        width: 130px;
    }
}

.main-content > :first-child:not(nav):not(.nav):not(.container):not(.sub-header) {
    margin-top: map-get($spacers, 4);
}

.page-title {margin-top:0rem!important;}
.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .7);
}

.loadable.loading .loading-overlay { display: flex; }


label.required {
    #sylius-product-variants &::after {
        content:''
    }
}

.btn-icon {
    padding-right: 5px;
    padding-left: 5px;
    //font-size: $font-size-lg;
}

.breadcrumbs {
    text-align: left;
}
.sub-header{
    background-color: #e9ecef;
}

.dot {
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    display: inline-block;
}

.card-body {
    padding: 0.75rem;
}
.card-product-img-container {
    height: 140px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.width-100 {
    width:100%!important;
}

.width-50 {
    width:50%!important;
}

.max-width-100 {
    max-width:100%;
}

/* Compacted sm-tables */
.table-sm th, .table-sm td {
    padding: 0.2rem;
}

/* compacted alert override */
.alert {
    padding: 12px;
    border: 2px solid transparent;
    border-radius: 2px;
}

/*Menu hover override*/
.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #f8f9fa!important;
}

/*Checkout steps highlight*/
.steps .steps-item.active
{
    background: #f15a22;
    color: #fff !important;
    .steps-text, .steps-icon{
        color: #fff !important;
    }
}

.steps .steps-item.completed {
    background: #f8f9fa;
    color: #adb5bd !important;

    .steps-text, .steps-icon{
        color: #adb5bd;
    }
}

.pagination {
    padding: 16px 0px;
    text-align: right;
    .pages > li {
        font-size: 15px;
        &.active {
            background-color: #f15a22;
            color: #fff;
            border-radius: 4px;
            line-height: 36px;
            text-decoration: none;
        }
        &:first-child {
            margin-right:5px;
        }
        &:last-child {
            margin-left:5px;
        }
        a {
            padding: 0 5px;
        }
    }
}

/* Hero slider */
.hero-slider {
    min-height: 450px;
}

.hero-slider > .owl-carousel {
    min-height: 450px;
}

.btn-orange
{
    background-color: #f15a22;
    color: #fff;
    &:hover{
        background-color: #CF5721;
        color: #fff;
    }
}

.btn-primary:hover {
    background-color: #d66d46;
}

.btn-primary:hover {
    color: #fff;
    background-color: #d66d46;
    border-color: #d66d46;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #f15a22;
    border-color: #f15a22;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: none;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: none;
}

.cursor-pointer{
    cursor: pointer;
}

.fltrow{
    border: 1px solid #eee !important;
    background-color: #ededed !important;
    text-align: center;
}
table.TF th {
    border-color: #eee !important;
    height: 28px;
    font-weight:900;
}
.flt, select.flt, select.flt_multi, .flt_s, .single_flt, .div_checklist {
    border: 1px solid #777 !important;
}
.fltrow th:last-child, .fltrow td:last-child {
    border-right: 1px solid #eee !important;
}
table.TF td {
    padding: 3px !important;
}
.flt, select.flt, select.flt_multi, .flt_s, .single_flt, .div_checklist {
    border: 1px solid #a4bed4;
}
.flt, .flt_s, .single_flt {
    height: 34px!important;
}
.flt, .flt_s, .single_flt {
    margin: 3px!important;
    border-radius: 0!important;
    width: 95%!important;
    width: -moz-available!important;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available!important;  /* Mozilla-based browsers will ignore this. */
    width: fill-available!important;
}

element.style {
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

table.TF th {
    margin: 0;
    color: inherit;
    background-color: transparent;
    border-color: transparent;
    border-width: 1px;
    border-style: solid;
}
table.TF th {
    height: 28px!important;
    margin: 0;
    background-color: #eaeaea;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #f4f4f4;
    border-left: 1px solid #ccc;
    border-right: 1px solid #f4f4f4;
    padding: .1em .7em;
    color: #333;
}
.table td {
    padding: 0.5rem;
    vertical-align: middle;
}

.flt, .flt_s, .single_flt {
    padding: 0 .5em 0 .5em !important;
}

.btn-sm, .btn-group-sm > .btn {
    height: 30px;
    padding: 0 12px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 28px;
}

.btn-xs {
    line-height: 16px;
    font-size:12px;
    padding:2px;
    height: 24px;
}

.form-control-sm:not(textarea) {
    height: 30px;
}

.form-control-xs:not(textarea) {
    height: 24px;
}

.form-control:focus {
    background-color: #fff;
}

.odd {
    background-color: #d0efff !important;
}

.filtered-table.TF input[type=radio]:hover
{
    cursor: pointer;
}

.filtered-table tr.highlight-row
{
    background-color: #fcd6c8!important;
}

#sylius_cart_promotionCoupon {
    width:65%!important;
}

/* list group item*/
.list-group-item {
    font-size: 14px;
}
a.list-group-item {
    padding-top: .57rem;
    padding-bottom: .57rem;
}

/*Dropdown*/
.dropdown-menu {
    border-radius: 2px;
    left:-1px;
    padding: 0.25rem 0;
}
.show .dropdown-menu {
    left:-1px !important;
}
.dropdown-menu .dropdown-item {
    font-weight:300;
    font-size:12px;
}
.dropdown-menu .dropdown-item:hover {
    color: inherit;
}
.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .btn {
    border-radius: 2px;
}

.badge {
    font-size: 80%;
}

label.required:after {
    content: "* ";
}
.hide-me{display:none !important;}
